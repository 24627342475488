import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import './Portfolio.css';
import github from '../../svg/github-mark.svg';
import email from '../../svg/email.svg';
import download from '../../svg/download.svg';
import cv from '../../otros/CV.pdf';
import feltrex from '../../img/feltrex.webp';
import fexpa from '../../img/fexpa.webp';
import gudako_maid from '../../img/gudako_maid.webp';
import calculadora40k from '../../img/calculadora40k.webp';

function Portfolio() {
  const [currentItem, setCurrentItem] = useState(0);
  const [nextItem, setNextItem] = useState(0);
  const [transition, setTransition] = useState(false);
  const [dateTransition, setDateTransition] = useState(false);
  const divRef = useRef(null);
  const [itemsCreated, setItemsCreated] = useState(false);
  const [lastTouchY, setLastTouchY] = useState(0);
  const [option, setOption] = useState('');

  const items = useMemo(() => [
    {
      date_start: 'ENE-2021',
      date_end: 'ABR-2021',
      title: 'Analista QA en DNK WATER S.P.A.',
      description: 'Monitoreo de la <strong>calidad</strong> de la página web de la empresa, creación de <strong>pruebas automatizadas</strong> y <strong>manuales</strong>, reporte de errores y mejoras en la página web.'
    },
    {
      date_start: 'FEB-2022',
      date_end: 'MAY-2022',
      title: 'Desarrollador en SODIMAC S.A.',
      description: 'Desarrollador de Aplicaciones en el <strong>Proyecto de Rendiciones de Turnos y Horas Extras</strong>, desarrollado con <strong>Django</strong> y <strong>SQL Server</strong>.'
    },
    {
      date_start: 'NOV-2022',
      date_end: 'ENE-2023',
      title: 'Desarrollador Freelance en Feltrex SpA.',
      description: 'Desarrollo del <strong>Sistema de Anexos del personal de la empresa</strong>, desarrollado en <strong>Electron</strong> y <strong>SQL Server</strong>.'
    },
    {
      date_start: 'ENE-2023',
      date_end: 'FEB-2023',
      title: 'Desarrollador Freelance en FEXPA INDUSTRIAL S.A.',
      description: 'Desarrollo de una <strong>página web</strong> para calcular el tipo de manga exotérmica a utilizar, desarrollada en <strong>React</strong>, <strong>NodeJS</strong> y <strong>Electron</strong>.'
    },
    {
      date_start: 'ENE-2023',
      date_end: 'Actualidad',
      title: 'Desarrollador y Analista TI en Zener Austral Limitada.',
      description: 'Desarrollo de <strong>página web</strong> de uso interno para la empresa, desarrollada en <strong>VBA</strong>, <strong>ASP.NET</strong> y <strong>SQL Server</strong>.'
    }
  ], []);

  const paintCircle = useCallback((currentItem) => {
    const items = document.querySelectorAll('.item');
    items.forEach((item, i) => {
      if (i === currentItem) {
        item.classList.add('selected');
      } else {
        item.classList.remove('selected');
      }
    });
  }, []);

  const handleWheel = useCallback((event) => {
    if (currentItem === items.length - 1 && event.deltaY > 0) {
      return;
    }
    if (currentItem === 0 && event.deltaY < 0) {
      return;
    }
    event.preventDefault();
    if (!transition) {
      if (event.deltaY > 0) {
        setNextItem((currentItem + 1) % items.length);
        paintCircle((currentItem + 1) % items.length);
      } else {
        setNextItem((currentItem - 1 + items.length) % items.length);
        paintCircle((currentItem - 1 + items.length) % items.length);
      }
      setTransition(true);
      setDateTransition(true);
    }
  }, [currentItem, items.length, transition, paintCircle]);

  const handleTouchMove = useCallback((event) => {
    if (event.touches.length !== 1) return;
    const currentTouchY = event.touches[0].clientY;
    const deltaY = currentTouchY - lastTouchY;

    if (currentItem === items.length - 1 && deltaY < 0) return;
    if (currentItem === 0 && deltaY > 0) return;

    event.preventDefault();
    event.stopPropagation();

    if (!transition) {
      if (deltaY < 0) {
        setNextItem((currentItem + 1) % items.length);
        paintCircle((currentItem + 1) % items.length);
      } else if (deltaY > 0) {
        setNextItem((currentItem - 1 + items.length) % items.length);
        paintCircle((currentItem - 1 + items.length) % items.length);
      }
      setTransition(true);
      setDateTransition(true);
    }
    setLastTouchY(currentTouchY);
  }, [currentItem, items.length, lastTouchY, transition, paintCircle]);

  useEffect(() => {
    const div = divRef.current;
    div.addEventListener('wheel', handleWheel, { passive: false });
    div.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      div.removeEventListener('wheel', handleWheel);
      div.removeEventListener('touchmove', handleTouchMove);
    };
  }, [handleWheel, handleTouchMove]);

  useEffect(() => {
    if (transition) {
      const timeout = setTimeout(() => {
        setCurrentItem(nextItem);
        setTransition(false);
        setDateTransition(false);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [transition, nextItem]);

  const createItems = useCallback(() => {
    const sphere = document.querySelector('.sphere-container');
    const radius = 100;
    const centerX = 250;
    const centerY = 0;

    items.forEach((item, i) => {
      const angle = (i * Math.PI) / 4;
      const x = centerX + radius * Math.cos(angle - Math.PI / 2);
      const y = centerY + radius * Math.sin(angle - Math.PI / 2);
      const circle = document.createElement('div');
      circle.classList.add('item');
      circle.style.left = `${x}px`;
      circle.style.top = `${y}px`;
      circle.id = `item-${i}`;
      circle.addEventListener('click', () => {
        if (!transition) {
          setNextItem(i);
          setTransition(true);
          setDateTransition(true);
          paintCircle(i);
        }
      });
      sphere.appendChild(circle);
    });
  }, [items, transition, paintCircle]);

  useEffect(() => {
    if (!itemsCreated) {
      createItems();
      paintCircle(currentItem);
      setItemsCreated(true);
    }
  }, [itemsCreated, createItems, currentItem, paintCircle]);

  useEffect(() => {
    if (option !== '') {
      document.getElementById(option).scrollIntoView({ behavior: 'smooth' });
    }
  }, [option]);

  return (
    <div className="Portfolio">
      <div className='header'>
        <div className='option' id='option-about' onClick={() => setOption('about')}>Sobre mi</div>
        <div className='option' id='option-timeline' onClick={() => setOption('timeline')}>Experiencia</div>
        <div className='option' id='option-proyects' onClick={() => setOption('proyects')}>Proyectos</div>
      </div>
      <div className='about' id='about'>
      </div>
      <div ref={divRef} className='timeline' id='timeline'>
        <div className='sphere'>
          <div className='sphere-container'>
          </div>
          <div className={`date ${dateTransition ? 'fade-date-out' : ''}`}>
            {items[currentItem].date_start}
            <span>-</span>
            {items[currentItem].date_end}
          </div>
          <div className={`date new-date ${dateTransition ? 'fade-date-in' : ''}`}>
            {items[nextItem].date_start}
            <span>-</span>
            {items[nextItem].date_end}
          </div>
        </div>
        <div className={`container-right ${transition ? 'fade-out' : ''}`}>
          <div className='content'>
            <p className='title'>{items[currentItem].title}</p>
            <p className='description' dangerouslySetInnerHTML={{ __html: items[currentItem].description }}></p>
          </div>
        </div>
        <div className={`container-right new-container ${transition ? 'slide-in' : ''}`}>
          <div className='content'>
            <p className='title'>{items[nextItem].title}</p>
            <p className='description' dangerouslySetInnerHTML={{ __html: items[nextItem].description }}></p>
          </div>
        </div>
      </div>
      <div className='proyects' id='proyects'>
        <div className='Head-title'>
          <div className='decoration' />
          <div className='title'>Resumen de Proyectos Desarrollados</div>
        </div>
        <div className='proyect primary'>
          <div className='proyect-img-container'>
            <img src={feltrex} alt='Feltrex' />
          </div>
          <div className='proyect-info'>
            <div className='proyect-title'>Sistema de Anexos para Feltrex SpA.</div>
            <div className='proyect-description'>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Mollitia modi consectetur consequuntur natus, laudantium, voluptatibus suscipit iusto eius perspiciatis ratione nulla at esse architecto illum fugiat aliquid sit temporibus rerum.
            </div>
          </div>
        </div>
        <div className='proyect secondary'>
          <div className='proyect-img-container'>
            <img src={fexpa} alt='Fexpa' />
          </div>
          <div className='proyect-info'>
            <div className='proyect-title'>Calculadora de Manga Exotérmica para Fexpa Industrial S.A.</div>
            <div className='proyect-description'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore delectus qui ducimus libero dolorem quos maiores, voluptate pariatur sunt reiciendis deleniti facere iusto totam. Ut nobis tempore voluptatum laudantium placeat?
            </div>
          </div>
        </div>
        <div className='proyect primary'>
          <div className='proyect-img-container'>
            <img src={gudako_maid} alt='Gudako Maid' />
          </div>
          <div className='proyect-info'>
            <div className='proyect-title'>Bot de Discord Gudako Maid</div>
            <div className='proyect-description'>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita, accusantium non perspiciatis esse sint, velit inventore unde, officiis quidem ipsam in aliquid. Tempora sunt alias reprehenderit facere quam consectetur labore?
            </div>
          </div>
        </div>
        <div className='proyect secondary'>
          <div className='proyect-img-container'>
            <img src={calculadora40k} alt='Calculadora WH40k' />
          </div>
          <div className='proyect-info'>
            <div className='proyect-title'>Calculadora de dados para Warhammer 40k</div>
            <div className='proyect-description'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime temporibus fugiat exercitationem officiis necessitatibus quo, quasi, id, corporis dignissimos impedit enim eligendi repellat harum itaque nisi aperiam neque aut? Ea.
            </div>
          </div>
        </div>
        <div className='line' />
      </div>
      <div className='footer'>
        <div className='social'>
          <a href='https://github.com/Chris99fm' target='_blank' rel='noreferrer'>
            <img src={github} alt='GitHub' />
          </a>
          <a href='mailto:chris99fm@outlook.com'>
            <img src={email} alt='Email' />
          </a>
          <a href={cv} target='_blank' rel='noreferrer'>
            <img src={download} alt='CV' />
          </a>
        </div>
        <div className='copy'>
          Copyright © 2024 Christian Fuentes
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
