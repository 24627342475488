import React, { useState } from 'react';
import './Calculadora.css';

function Calculadora() {
  const [ndados, setNdados] = useState(0);
  const [nmin, setNmin] = useState(4);
  const [ncritico, setNcritico] = useState(6);
  const [usarSustained, setUsarSustained] = useState(false);
  const [usarLethal, setUsarLethal] = useState(false);
  const [nletal, setNletal] = useState(0);
  const [nStrength, setNStrength] = useState(4);
  const [nToughness, setNToughness] = useState(4);
  const [nWcritico, setNWcritico] = useState(6);
  const [usarDevastating, setUsarDevastating] = useState(false);
  const [ndevastating, setNdevastating] = useState(0);
  const [usarRerollH, setUsarRerollH] = useState(false);
  const [usarRerollW, setUsarRerollW] = useState(false);
  const [resultadoH, setResultadoH] = useState(0);
  const [resultadoW, setResultadoW] = useState(0);

  const calcular = () => {
    calcular_hit();
    calcular_wound();
  };

  React.useEffect(() => {
    calcular();
  }, [ndados, nmin, ncritico, usarSustained, usarLethal, usarRerollH, nStrength, nToughness, nWcritico, usarDevastating, usarRerollW]);

  const calcular_hit = () => {
    const p_min = (6 - nmin + 1) / 6;
    const p_critico = (6 - ncritico + 1) / 6; 
    let T_hits = ndados * p_min;

    if (usarRerollH) {
        T_hits += ndados * p_min * (1 - p_min);
    }

    if (usarSustained) {
        T_hits += ndados * p_critico;
    }

    let T_lethal = 0;
    if (usarLethal) {
        T_lethal = ndados * p_critico;
        setNletal(T_lethal);
    }

    const E_total = T_hits;
    setResultadoH(E_total);
  };

  const calcular_wound = () => {
    const S = nStrength;
    const T = nToughness;
    let objetivo = 0;
    if (S === T) {
        objetivo = 4;
    } else if (S > T) {
        objetivo = 3;
    } else if (S < T) {
        objetivo = 5;
    } else if (S >= 2 * T) {
        objetivo = 2;
    } else if (S < 2 * T) {
        objetivo = 6;
    }
    const min = (6 - objetivo + 1) / 6;
    const p_critico = (6 - nWcritico + 1) / 6;
    let T_wounds = resultadoH * min;

    if (usarRerollW) {
        T_wounds += resultadoH * min * (1 - min);
    }

    if (usarDevastating) {
        setNdevastating(resultadoH * p_critico);
    }

    setResultadoW(T_wounds);

  }


  return (
    <div className="calculadora-container" id="calculadora">
      <h2 className="calculadora-heading">Calculadora de Dados</h2>
      <div className="calculadora-label">
        <label>
          Número de dados:
          <input type="number" className="calculadora-input" value={ndados} onChange={(e) => setNdados(Number(e.target.value))} min={1} step={1} />
        </label>
      </div>
      <div className="calculadora-label">
        <label>
          Ballistic Skill / Weapon Skill / To Hit:
          <input type="number" className="calculadora-input" value={nmin} onChange={(e) => setNmin(Number(e.target.value))} min={1} max={6} />
        </label>
      </div>
      <div className="calculadora-label">
        <label>
          Valor Hit crítico:
          <input type="number" className="calculadora-input" value={ncritico} onChange={(e) => setNcritico(Number(e.target.value))} min={1} max={6} />
        </label>
      </div>
      <div className="calculadora-label">
        <label>
          Sustained hits:
          <input type="checkbox" className="calculadora-checkbox" checked={usarSustained} onChange={(e) => setUsarSustained(e.target.checked)} min={1} max={6} />
        </label>
      </div>
      <div className="calculadora-label">
        <label>
          Lethal hits:
          <input type="checkbox" className="calculadora-checkbox" checked={usarLethal} onChange={(e) => setUsarLethal(e.target.checked)} min={1} max={6} />
        </label>
      </div>
      <div className="calculadora-label">
        <label>
          Reroll:
          <input type="checkbox" className="calculadora-checkbox" checked={usarRerollH} onChange={(e) => setUsarRerollH(e.target.checked)} />
        </label>
      </div>
      <div className="calculadora-label">
        <label>
          Strength:
          <input type="number" className="calculadora-input" value={nStrength} onChange={(e) => setNStrength(Number(e.target.value))} min={1} />
        </label>
      </div>
      <div className="calculadora-label">
        <label>
          Toughness:
          <input type="number" className="calculadora-input" value={nToughness} onChange={(e) => setNToughness(Number(e.target.value))} min={1} />
        </label>
      </div>
      <div className="calculadora-label">
        <label>
          Valor Wound crítico:
          <input type="number" className="calculadora-input" value={nWcritico} onChange={(e) => setNWcritico(Number(e.target.value))} min={1} max={6} />
        </label>
      </div>
      <div className="calculadora-label">
        <label>
          Devastating Wounds:
          <input type="checkbox" className="calculadora-checkbox" checked={usarDevastating} onChange={(e) => setUsarDevastating(e.target.checked)} />
        </label>
      </div>
      <div className="calculadora-label">
        <label>
          Reroll:
          <input type="checkbox" className="calculadora-checkbox" checked={usarRerollW} onChange={(e) => setUsarRerollW(e.target.checked)} />
        </label>
      </div>
      <button className="calculadora-button" onClick={calcular}>Calcular</button>
      <div className="calculadora-resultado" id="resultadoHit">Resultado Hit: {resultadoH.toFixed(2)}</div>
      <div className="calculadora-resultado" id="resultadoWound">Resultado Wound: {resultadoW.toFixed(2)}</div>
      {usarLethal && <div className="calculadora-resultado" id="letal">Lethal: {nletal.toFixed(2)}</div>}
      {usarDevastating && <div className="calculadora-resultado" id="devastating">Devastating: {ndevastating.toFixed(2)}</div>}
    </div>
  );
}  



export default Calculadora;
