import React from 'react';
import './Mantenimiento.css';
import gudako from '../../img/gudako.webp';

function Mantenimiento() {
    return (
        <div className="mantenimiento-container">
            <div className="img-container">
                <img src={gudako} alt="Gudako" />
            </div>
            <div className="typewriter-container">
                <div className="typewriter">
                <h1>Pagina en mantenimiento, espere por favor.</h1>
                </div>
            </div>
        </div>
      );
}

export default Mantenimiento